html {
  scroll-behavior: smooth !important;
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #2784C8;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #2784C8;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
